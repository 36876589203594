import { useState, useEffect } from 'react'
import { gql } from 'graphql-request'
import { useMutation } from '@tanstack/react-query'
import { useForm, useFieldArray } from 'react-hook-form'

import { request } from '@helpers/graphql'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import Button from '@components/Button'
import CircleSpinner from '@components/CircleSpinner'
import CopyToClipboardInput from '@components/CopyToClipboardInput'
import DismissiblePill from '@components/DismissiblePill'
import Label from '@components/Label'
import Modal from '@components/Modal'
import TextArea from '@components/TextArea'
import { useFlashMessage } from '@components/FlashMessage'

import { useOnboardingChecklist } from '../../hooks/onboardingChecklist'

const INVITE_EDUCATORS_TO_ORGANIZATION_MUTATION = gql`
  mutation inviteEducatorsToOrganization($inviteEducatorsToOrganizationInput: InviteEducatorsToOrganizationInput!) {
    inviteEducatorsToOrganization(input: $inviteEducatorsToOrganizationInput) {
      success
      invalidEmailAddresses
    }
  }
`

const CREATE_OR_GET_ORGANIZATION_JOIN_CODE_MUTATION = gql`
  mutation createOrGetOrganizationJoinCode($input: CreateOrGetOrganizationJoinCodeInput!) {
    createOrGetOrganizationJoinCode(input: $input) {
      organizationJoinCode {
        token
      }
      success
      errors
    }
  }
`

const RESET_ORGANIZATION_JOIN_CODE_MUTATION = gql`
  mutation resetOrganizationJoinCode($input: ResetOrganizationJoinCodeInput!) {
    resetOrganizationJoinCode(input: $input) {
      organizationJoinCode {
        token
      }
      success
      errors
    }
  }
`

const isValidEmail = email => /^\S+@\S+\.\S+$/.test(email)

const InviteEducatorsModal = ({ organizationId, closeModal, isOpen }) => {
  const [invitationToken, setInvitationToken] = useState('')

  const { setFlashMessage } = useFlashMessage()
  const { markOnboardingItemCompleted } = useOnboardingChecklist()
  const { handleSubmit, reset, setError, clearErrors, control, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: {
      emailAddresses: []
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emailAddresses'
  })
  const { mutateAsync: inviteEducators, isLoading: sending } = useMutation({
    mutationFn: async variables => request(INVITE_EDUCATORS_TO_ORGANIZATION_MUTATION, { inviteEducatorsToOrganizationInput: variables }),
    onSettled: response => {
      const data = response?.inviteEducatorsToOrganization

      if (data.success === true) {
        setFlashMessage('Invitations sent', 'success')
        reset()
        closeModal()
        markOnboardingItemCompleted('invitedEducators')
      } else {
        const message = data.invalidEmailAddresses.length > 0
          ? 'Some of the email addresses listed are already associated with an account.'
          : 'Something went wrong'

        setError('general', { message })

        data.invalidEmailAddresses.forEach(invalidEmail => {
          const fieldIndex = fields.findIndex(field => field.email === invalidEmail)
          if (fieldIndex !== -1) {
            setError(`emailAddresses.${fieldIndex}`, { message: 'Invalid email' })
          }
        })
      }
    }
  })

  const addEmailAddress = email => {
    if (!fields.some(field => field.email === email)) {
      append({ email })
    }
  }

  const handlePaste = (e) => {
    clearErrors('general')

    const value = e.clipboardData.getData('text')
    const emails = value.split(/[,;\s]+/).map(email => email.trim()).filter(Boolean)

    if (emails.length > 0) {
      e.preventDefault() // Prevent the default paste
      emails.forEach(email => {
        if (isValidEmail(email)) {
          addEmailAddress(email)
        }
      })
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === ',' || e.key === ';') {
      e.preventDefault()

      const value = e.target.value.trim()

      if (isValidEmail(value)) {
        addEmailAddress(value)
        e.target.value = ''
      }
    }
  }

  const submit = () => {
    clearErrors()
    if (fields.length === 0) {
      setError('general', { message: 'Email addresses are required' })
      return
    }
    inviteEducators({ emailAddresses: fields.map(field => field.email) })
  }

  const { mutateAsync: createOrGetOrganizationJoinCode } = useMutation({
    mutationFn: async () => request(CREATE_OR_GET_ORGANIZATION_JOIN_CODE_MUTATION, { input: { organizationId } })
  })

  const { mutateAsync: resetOrganizationJoinCode, isLoading: isResettingInvitationLink } = useMutation({
    mutationFn: async () => request(RESET_ORGANIZATION_JOIN_CODE_MUTATION, { input: { organizationId } })
  })

  const resetInvitationToken = async () => {
    const { resetOrganizationJoinCode: { organizationJoinCode: { token } = {} } } = await resetOrganizationJoinCode()
    setInvitationToken(token)
  }

  useEffect(() => {
    const fetchToken = async () => {
      if (isOpen) {
        const data = await createOrGetOrganizationJoinCode()
        const { createOrGetOrganizationJoinCode: { organizationJoinCode: { token } = {}, success } } = data

        if (success) setInvitationToken(token)
      }
    }

    fetchToken()
  }, [isOpen])

  return (
    <Modal size='md' isOpen={isOpen} onClose={closeModal}>
      <form onSubmit={handleSubmit(submit)} className='flex flex-col'>
        <h2 className='font-heading text-3xl font-bold leading-tight'>Invite educators</h2>

        <div>
          <div className='flex gap-3 mt-5 mb-3 items-end'>
            <CopyToClipboardInput
              value={`${window.location.origin}/educator_accept_invitation?invitation_token=${invitationToken}`}
              label='Invite link'
              className='w-full'
            />

            <Button
              className='h-[36px] flex items-center justify-center'
              variant='outlined'
              theme='light'
              type='button'
              onClick={() => resetInvitationToken()}
              label={
                <span className='flex gap-2 items-center'>
                  <Choose>
                    <When condition={isResettingInvitationLink}>
                      <CircleSpinner className='size-5' />
                    </When>

                    <Otherwise>
                      <ArrowPathIcon className='size-5' />
                    </Otherwise>
                  </Choose>
                </span>
              }
            />
          </div>

          <Label htmlFor='emails' title='Email Addresses' />
          <p className='mt-0 mb-3'>Add multiple email addresses by separating them with commas, spaces, or semicolons.</p>

          <div className='flex flex-wrap mb-1'>
            <For each='field' index='index' of={fields}>
              <With hasError={errors.emailAddresses?.[index]}>
                <DismissiblePill
                  key={field.id}
                  label={field.email}
                  theme={hasError ? 'error' : 'light'}
                  aria-invalid={hasError}
                  aria-errormessage={hasError ? `Email address ${field.email} is invalid` : undefined}
                  onDismiss={() => {
                    remove(index)
                    clearErrors(`emailAddresses.${index}`)
                    clearErrors('general')
                  }}
                />
              </With>
            </For>
          </div>

          <TextArea
            id='emails'
            placeholder='educator@school.edu, administrator@school.edu, ...'
            onPaste={handlePaste}
            onKeyDown={handleKeyDown}
            className='w-full'
            rows={3}
            autoFocus
          />
        </div>

        <If condition={errors.general}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>
            {errors.general.message}
          </p>
        </If>

        <Button
          disabled={errors.general || errors.emailAddresses || sending}
          className='w-fit ml-auto mt-5 disabled:opacity-50'
          type='submit'
          label={sending ? 'Sending...' : `Send invitations (${fields.length})`}
        />
      </form>
    </Modal>
  )
}

export default InviteEducatorsModal
