import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { gql } from 'graphql-request'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import Table from '@components/Table'
import Spinner from '@components/Spinner'

import { useCurrentUser } from '@contexts/currentUser'
import InviteEducatorsModal from './InviteEducatorsModal'
import EducatorListActions from './EducatorListActions'

const EDUCATORS_QUERY = gql`
  query organization {
    organization {
      id
      educatorProfiles {
        id
        fullName
        email
        authorizationRole
      }
    }
  }
`

const EducatorList = () => {
  const { user: { email: currentUserEmail, authorization_role: currentUserAuthRole } = {} } = useCurrentUser()

  const [showInviteModal, setShowInviteModal] = useState(false)

  const { isLoading, data: { organization = {} } = {}, refetch } = useQuery({
    queryKey: ['educators'],
    queryFn: async () => await request(EDUCATORS_QUERY)
  })

  if (isLoading) {
    return <Spinner className='relative top-0 left-0 flex items-center justify-center w-full h-screen' />
  }

  return (
    <>
      <div className='flex flex-col w-full pb-10'>
        <Button
          className='ml-auto mb-3 mr-5 md:mr-0'
          label='Invite educators'
          onClick={() => setShowInviteModal(true)}
        />

        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Header>Name</Table.Header>
              <Table.Header>Email</Table.Header>
              <Table.Header>Role</Table.Header>
              <Table.Header />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            <For each='educator' of={organization.educatorProfiles}>
              <Table.Row key={educator.id}>
                <Table.Cell>{educator.fullName}</Table.Cell>
                <Table.Cell>{educator.email}</Table.Cell>
                <Table.Cell>{educator.authorizationRole.toLowerCase()}</Table.Cell>
                <Table.Cell className='flex items-center justify-end'>
                  <If condition={currentUserAuthRole === 'admin' && currentUserEmail !== educator.email}>
                    <EducatorListActions educator={educator} refetch={refetch} />
                  </If>
                </Table.Cell>
              </Table.Row>
            </For>
          </Table.Body>
        </Table>
      </div>

      <InviteEducatorsModal
        organizationId={organization.id}
        isOpen={showInviteModal}
        closeModal={() => setShowInviteModal(false)}
      />
    </>
  )
}

export default EducatorList
